import Button from '../Button';
import styles from './Footer.module.scss';
const Footer = () => {
    return (
        <footer className={styles.footer}>
            <div className={styles.copyright}>
                <p>2024 © Copyright. All Rights Reserved. Powered by TekoAI</p>
            </div>
            <div className={styles.footerTel}>
                <a href="tel:+3655500060">
                    <Button format="white">
                        (365) 550-0060
                    </Button>
                </a>
            </div>
        </footer>
    );
}

export default Footer;