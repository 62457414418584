import styles from './Reviews.module.scss';
const reviews = [
    {
        id: 1,
        name: 'Bosco',
        review: '"I went with my friend to this little blue house for a special anti-dandruff and anti-itch treatment. The head therapist had excellent techniques, and the results were very noticeable."',
        rating: 5,
        path: "https://maps.app.goo.gl/JTRaXdLJGd8GtRxs6",
    },
    {
        id: 2,
        name: 'M S',
        review: '"A very comfortable feeling, clean environment, and friendly attitude."',
        rating: 5,
        path: "https://maps.app.goo.gl/Mb7yX3Ytqo7VBTJX9",
    },
    {
        id: 3,
        name: 'F',
        review: '"I enjoyed a unique head massage in this little blue room. It felt very comfortable and relaxing—a place worth checking out."',
        rating: 5,
        path: "https://maps.app.goo.gl/r6tmhDpG3X7Sy2Ue7",
    },
    {
        id: 4,
        name: 'Johanna Slocombe',
        review: '"What an amazing experience. Came out with the softest healthiest hair of my life! You should manage your expectations, they do not style your hair. Highly recommend for a girls night or just a relaxing afternoon."',
        rating: 5,
        path: "https://maps.app.goo.gl/gMinEMWCkFFFiA9L8",
    },
    {
        id: 5,
        name: 'Dino Simatos',
        review: '"My wife and i liked what they\'ve done with the place. The environment is inviting, it has a home-like feel. From the moment we entered they were very kind and subtle, and put effort into explaining possible causes of my scalp condition."',
        rating: 5,
        path: "https://maps.app.goo.gl/k1KeLUcEKZDfZasq7",
    },
    {
        id: 6,
        name: 'Jagni Bhavsar',
        review: '"Went there yesterday and got my scalp treated by Amy and it was amazing! I struggle with a dry scalp and I walked out feeling completely cleansed and no irritation. This was much needed for my hair and I’m so happy with the results I definitely recommend."',
        rating: 5,
        path: "https://maps.app.goo.gl/gM7eN9XggN32GTVx9",
    }
]

const Reviews = () => {
    const starSvg = 'assets/images/star.svg';
    return (
        <div className={styles.reviewsWrapper}>
            <h2>Customer Reviews Are Our Inspiration</h2>
            <div className={styles.reviews}>
                {
                    reviews.map((review) => (
                        <div key={review.id} className={styles.singleReview}>
                            <h2>{review.name}</h2>
                            {
                                Array(review.rating).fill().map((_, index) => (
                                    <img key={index} src={starSvg} alt="star" />
                                ))
                            }
                            <p>
                                <a href={review.path}>
                                    {review.review}
                                </a>
                            </p>

                        </div>
                    ))
                }
            </div>
        </div>
    );
}

export default Reviews;