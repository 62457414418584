import styles from './Treatments.module.scss';
const treaments = [
    {
        id: 1,
        image: 'assets/images/treat1.png',
        title: 'Anti-Dandruff and Anti-Itch Conditioning Package',
        desc1: 'We present a conditioning package for dandruff, which is prepared with pure Chinese herbs, free of hormones and antibiotics, absolutely safe and without side effects. We use botanical herbal extracts that have antiseptic and anti-inflammatory properties, while effectively removing dandruff and stopping itching.',
        desc2: 'The process of this conditioning package has been carefully designed to include scalp detoxification, cleansing, herbal conditioning, hydration and repair, and blow-drying to ensure that your scalp receives total care. We are committed to bringing you scalp health and comfort through the magical power of herbs, so you can regain your confidence and say goodbye to dandruff and itchiness. Let\'s embark on a journey together to a healthy scalp that will bring you lasting hair health and beauty.'
    },
    {
        id: 2,
        image: 'assets/images/treat2.png',
        title: 'Oil & Water Balancing Package',
        desc1: 'We present a conditioning package for dandruff, which is prepared with pure Chinese herbs, free of hormones and antibiotics, absolutely safe and without side effects. We use botanical herbal extracts that have antiseptic and anti-inflammatory properties, while effectively removing dandruff and stopping itching.',
        desc2: 'The process of this package has been carefully designed to include scalp detoxification, cleansing, herbal conditioning, hydration and blow-drying to ensure that your scalp receives all-round care. We are committed to bringing you the health and comfort of your scalp so that you can say goodbye to your greasy worries. Let\'s embark on a journey of oil control and conditioning together to bring you long-lasting hair freshness and comfort, renewed confidence and radiance.'
    },
    {
        id: 3,
        image: 'assets/images/treat3.png',
        title: 'Anti-Hair Loss and Hair Strengthening Health Package',
        desc1: 'We bring to you a special anti-hair loss and hair strengthening treatment. This is a gentle yet highly effective treatment program designed to nourish the scalp and strengthen hair. Whether you are facing hair loss or want healthier hair, we have the solution for you.',
        desc2: 'Our treatment process is carefully designed to include a scalp detox, cleansing, follicle activation, follicle nourishment and a blowout. We insist on a detox every three days and daily cleansing, nourishing and follicle activation to ensure your hair is fully pampered.'
    },
    {
        id: 4,
        image: 'assets/images/treat4.png',
        title: 'Scalp Detox Treatment',
        desc1: 'Designed to provide you with a comprehensive head treatment and detoxification experience. This treatment not only helps to detoxify the body, but also provides effective headache relief.',
        desc2: 'Our head detox process has been carefully designed to include a scalp test, head spotting, cleansing oil rub, head rasp, heat, shoulder and neck detox, cleansing and conditioning steps. We use specific techniques and natural ingredients to provide you with a deeply detoxifying and comforting head experience.'
    },
    {
        id: 5,
        image: 'assets/images/treat5.png',
        title: 'Scalp Anti-Aging Treatment',
        desc1: 'This package not only slows down the aging of the scalp, but also helps to firm the skin on the face and reduce the appearance of wrinkles, giving you a healthy and youthful scalp.',
        desc2: 'We understand how important the health and beauty of the scalp is to your overall appearance, which is why our anti-aging packages have been carefully designed to incorporate specialized techniques to provide you with comprehensive scalp care.'
    },
    {
        id: 6,
        image: 'assets/images/treat6.png',
        title: 'Signature Facial',
        desc1: 'Including Projects:',
        desc2: [
            "Micro-Bubble Deep Cleansing or Point Shock Head Knife",
            "High Frequency (Antibacterial, Anti-inflammatory, and Pore-Refining)",
            "Nano Atomizer",
            "Micro Current Lifting",
            "Ultrasonic Essence Infusion",
            "Brightening Mask",
            "LED Light",
        ]
    }
]

const Treatments = () => {
    const treatBgSvg = 'assets/images/treatbg.svg';
    return (
        <div className={styles.treatmentsWrapper}>
            <h2>All Treatment at One Day Retreat</h2>
            <div className={styles.treatments}>
                {
                    treaments.map((treatment) => (
                        <div key={treatment.id} className={styles.singleTreatment}>
                            <img src={treatment.image} alt={treatment.title} />
                            <div className={styles.treatText}
                                style={{
                                    backgroundImage: `url(${treatBgSvg})`
                                }}>
                                <h2>{treatment.title}</h2>
                                <p>{treatment.desc1}</p>
                                {
                                    typeof treatment.desc2 === 'string' ? <p>{treatment.desc2}</p> :
                                        <ul className={styles.treatUl}>
                                            {
                                                treatment.desc2.map((desc, index) => (
                                                    <li key={index}>{desc}</li>
                                                ))
                                            }
                                        </ul>
                                }
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    );
}

export default Treatments;