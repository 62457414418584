import styles from './Feature.module.scss';
const features = [
    {
        id: 1,
        title: 'Signature Facial',
        time: '90 minutes',
        description: 'Experience price $159 (Regular $259)'
    },
    {
        id: 2,
        title: 'Oil and Water Balancing',
        time: '75 minutes',
        description: 'Experience price $149 (Regular $188)'
    },
    {
        id: 3,
        title: 'Antidandruff and Anti Itching',
        time: '75 minutes',
        description: 'Experience price $159 (Regular $198)'
    },
    {
        id: 4,
        title: 'Scalp Detox and Anti-aging',
        time: '75 minutes',
        description: 'Experience price $159 (Regular $198)'
    },
    {
        id: 5,
        title: 'Anti Hair Loss',
        time: '90 minutes',
        description: 'Experience price $189 (Regular $238)'
    },
    {
        id: 6,
        title: 'Muscle Relaxation',
        time: '60 minutes',
        description: 'Experience price $89 (Regular $129)'
    },
]
const Feature = () => {
    return (
        <div className={styles.features}>
            {
                features.map((feature) => (
                    <div key={feature.id} className={styles.featureLists}>
                        <div className={styles.featureBullets}>{feature.id}</div>
                        <h2 className={styles.featureTitle}>{feature.title}</h2>
                        <h2 className={styles.featureTime}>({feature.time})</h2>
                        <p className={styles.featureDesc}>{feature.description}</p>
                    </div>
                ))
            }
        </div>
    );
}

export default Feature;