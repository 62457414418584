import './App.css';
import Header from './components/Header';
import Banner from './components/Banner';
import Feature from './components/Feature';
import Reviews from './components/Reviews';
import WhyChoose from './components/WhyChoose';
import Environment from './components/Environment';
import Treatments from './components/Treatments';
import Footer from './components/Footer';
import Map from './components/Map';
import Float from './components/Float';
import { useWindowScrollPositions } from './hooks/useWindowScrollPositions';
import { useIsMobile } from './hooks/useIsMobile';
function App() {
  const { scrollY } = useWindowScrollPositions();
  return (
    <div className="App">
      <Header />
      <Banner />
      <Feature />
      <Reviews />
      <WhyChoose />
      <Environment />
      <Treatments />
      <Map />
      <Footer />
      {
       !useIsMobile() && scrollY > 100 ? <Float /> : null
      }
       {
       useIsMobile() && scrollY > 560 ? <Float /> : null
      }
    </div>
  );
}

export default App;
