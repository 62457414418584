import styles from './WhyChoose.module.scss';
const reasons = [
    {
        id: 1,
        image: 'assets/images/why1.png',
        title: 'More Services',
        description: 'Our services offer a holistic one-day retreat focused on personal well-being, integrating mental and physical health practices for a rejuvenating experience.'
    },
    {
        id: 2,
        image: 'assets/images/why2.png',
        title: 'Enhanced Water Quality',
        description: 'Our new water system ensures the highest standards of health and wellness.'
    },
    {
        id: 3,
        image: 'assets/images/why3.png',
        title: 'Professional Team',
        description: 'Our staff have undergone extensive training to bring you the best care possible.'
    },
]

const WhyChoose = () => {
    return (
        <div className={styles.whyChooseWrapper}>
            <h2>Why Choose One Day Retreat</h2>
            <div className={styles.whyChoose}>
                {
                    reasons.map((reason) => (
                        <div key={reason.id} className={styles.whyReason}>
                            <img src={reason.image} alt={reason.title} />
                            <div className={styles.whyText}>
                                <h2>{reason.title}</h2>
                                <p>{reason.description}</p>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    );
}

export default WhyChoose;