import Button from '../Button';
import Contact from '../Contact';
import styles from './Banner.module.scss';
import { useIsMobile } from '../../hooks/useIsMobile';
import { useState } from 'react';
const Banner = () => {
    const bannerImage = 'assets/images/banner.svg';
    const bannerGradient = 'assets/images/bannerGradient.svg';
    const [showContact, setShowContact] = useState(false);
    const handleContact = () => {
        setShowContact(!showContact);
        if (!showContact) {
            document.body.style.overflow = 'hidden';
            document.body.style.height = '100vh';
        } else {
            document.body.style.overflow = 'auto';
            document.body.style.height = 'auto';
        }
    }
    return (
        <div className={styles.banner}>
            <div style={{
                backgroundImage: [
                    `url(${bannerGradient})`, 
                    `url(${bannerImage})`
                ],
                backgroundSize: 'cover',
                width: '100%',
                position: 'absolute',
                height: '100%',
                zIndex: '-1',
                filter: 'blur(3px)',
            }}>
            </div>
            <div className={styles.bannerText}>
                <h1>We are back!</h1>
                <div className={styles.bannerTitle}>
                    <p>Head SPA & Scalp</p>
                    <p>Inspection(75 minutes)</p>
                </div>
                <div className={styles.bannerSubTitle}>
                    <p>$79 for 1 person or $149 for 2 people</p>
                    <p>(Regular price $138 per person)</p>
                </div>
                <div className={styles.bannerButtons}>
                    <a href="tel:+3655500060">
                        <Button format="white">(365) 550-0060</Button>
                    </a>
                    {
                        useIsMobile() &&
                        <Button format="white"
                            onClick={handleContact}
                        >Contact Us</Button>
                    }
                </div>
                {
                    showContact &&
                    <div>
                        <div className={styles.bannerBlurBackground}></div>
                        <div className={styles.bannerPopup}>
                            <div className={styles.bannerBackground}></div>
                            <div className={styles.bannerContact}>
                                <Contact />
                                <div className={styles.bannerCross}
                                    onClick={handleContact}
                                >x</div>
                            </div>
                        </div>
                    </div>
                }
                <div className={styles.bannerWelcome}>
                    <p>
                        This summer, enjoy the elevated quality of our services.
                        Book your appointment now, as spots are limited and filling up fast!
                    </p>
                    <p>
                        We look forward to welcoming you!
                    </p>
                </div>
                <div className={styles.bannerBook}>
                    <a href="https://app.tekoai.com/appointment?botId=217">
                        <Button
                            format={useIsMobile() ? "white" : "default"}
                        >Book Now!</Button>
                    </a>
                    <Button
                        format={useIsMobile() ? "white" : "default"}
                        onClick={() => window.open("https://www.fresha.com/book-now/one-day-retreat-cdcpjf8k/gift-cards?pId=1094811")}
                    >Gift Card</Button>
                </div>
                <div className={styles.bannerLetter}>
                    <p>Dear Valued Customers,</p>
                    <p>
                        To show our gratitude for your continued support, we've upgraded our equipment, enhanced our water quality, and provided comprehensive training for our staff. Now, we're excited to offer a Summer Special Promotion, inviting you to experience our improved services!
                    </p>
                </div>
            </div>
            <div className={styles.bannerForm}>
                <Contact />
            </div>
        </div>
    );
}

export default Banner;