import styles from './Float.module.scss';
import Button from '../Button';
import Contact from '../Contact';
import { useState } from 'react';
import { useIsMobile } from '../../hooks/useIsMobile';

const Float = () => {
    const [showContact, setShowContact] = useState(false);
    const handleContact = () => {
        setShowContact(!showContact);
        if (!showContact) {
            document.body.style.overflow = 'hidden';
            document.body.style.height = '100vh';
        } else {
            document.body.style.overflow = 'auto';
            document.body.style.height = 'auto';
        }
    }

    return <div className={styles.floatWrapper}>
        <div className={styles.float}>
            <div className={styles.floatText}>
                <div className={styles.floatButtons}>
                    <Button format="white"
                        onClick={() => window.open("https://app.tekoai.com/appointment?botId=217")}
                    >
                        Book Now!
                    </Button>
                    <Button format="white"
                        onClick={() => window.open("https://www.fresha.com/book-now/one-day-retreat-cdcpjf8k/gift-cards?pId=1094811")}
                    >
                        Gift Card
                    </Button>
                    {
                        !useIsMobile() &&
                        <Button format="white"
                            onClick={handleContact}
                        >
                            Contact Us
                        </Button>
                    }

                </div>
            </div>
        </div>
        {
            showContact &&
            <div>
                <div className={styles.floatBackground}></div>
                <div className={styles.floatContact}>
                    <Contact />
                    <div className={styles.floatCross}
                        onClick={handleContact}
                    >x</div>
                </div>
            </div>
        }
    </div>;
}

export default Float;