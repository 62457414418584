import Button from '../Button';
import styles from './Header.module.scss';
const Header = () => {
    const locationSvg = "/assets/images/location.svg";
    const logoSvg = "/assets/images/logo.svg";
    return (
        <div className={styles.header}>
            <div className={styles.headerFlex}>
                <img src={locationSvg} alt="Location" />
                <div className={styles.location}>
                    <p>9861 Leslie St, Richmond Hill, </p>
                    <p>ON L4B 3Y4</p>
                </div>
            </div>
            <div className={styles.headerFlex}>
                <a href="https://www.onedayretreat.ca/">
                <img src={logoSvg} alt="Logo" />
                </a>
            </div>
            <div className={styles.headerFlex}>
                <div>Book Now!</div>
                <a href="tel:+3655500060">
                    <Button>
                        (365) 550-0060
                    </Button>
                </a>
            </div>
        </div>
    );
}

export default Header;