// contact form for book appointment
import Button from '../Button';
import styles from './Contact.module.scss';
import axios from 'axios';
import { useState } from 'react';

const Contact = () => {
    const apiUrl = 'https://test.tekoai.com/chatbotapi';

    const [formData, setFormData] = useState({
        name: '',
        phone_num: '',
        email: '',
        notes: '',
        bot_id: 217 
    });
    const [message, setMessage] = useState(''); 

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${apiUrl}/addWebBuildContact/addWebBuildContact`, formData);
            setMessage(response.data.message);
            setFormData({ name: '', phone_num: '', email: '', notes: '', bot_id: 217 });
        } catch (error) {
            setMessage('Error submitting the form');
        }
    };

    return (
        <div className={styles.contact}>
            <h2>Leave Us a Message</h2>
            <p>VIP room service, offering exclusive space. During the promotional period, reservations are confirmed on a first-come, first-served basis.</p>
            <form onSubmit={handleSubmit}>
            <div className={styles.contactForm}>
                <div className={styles.contactField}>
                    <input type="text" id="name" name="name" placeHolder="Name" value={formData.name} onChange={handleChange} required/>
                </div>
                <div className={styles.contactField}>
                    <input type="text" id="phone_num" name="phone_num" placeHolder="Phone" value={formData.phone_num} onChange={handleChange} required/>
                </div>
                <div className={styles.contactField}>
                    <input type="email" id="email" name="email" placeHolder="Email" value={formData.email} onChange={handleChange} required/>
                </div>
                <div className={styles.contactField}>
                    <textarea id="notes" name="notes" placeHolder="Message" value={formData.notes} onChange={handleChange} required />
                </div>
            </div>
            <Button type="submit">Contact Us</Button>
            {message && <p>{message}</p>}
            </form>
            
        </div>
    );
}

export default Contact;