import styles from './Map.module.scss';
const locationSvg = "/assets/images/location.svg";
const shopSvg = "/assets/images/shop.svg";
const Map = () => {
    return (
        <div className={styles.mapWrapper}>
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2875.9647806957832!2d-79.3929264!3d43.8772813!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b2b0d75daa955%3A0x3e4817a71c184530!2zT25lIERheSBSZXRyZWF0IOS4gOaXpei9u-eWlw!5e0!3m2!1sen!2sca!4v1722329240417!5m2!1sen!2sca"
                width="100%" height="480" style={{ border: 0 }}
                allowfullscreen="false" loading="lazy" referrerpolicy="no-referrer-when-downgrade"
                title='map'
                draggable="false"
            >
            </iframe>
            <div className={styles.mapText}>
                <div>
                    <div>
                        <img src={locationSvg} alt="Location" />
                    </div>
                    <div className={styles.mapTitle}>One Day Retreat 一日轻疗</div>
                    <div>9861 Leslie St, Richmond Hill,</div>
                    <div>ON L4B 3Y4</div>
                </div>
                <div>
                    <div>
                        <img src={shopSvg} alt="Shop" />
                    </div>
                    <div className={styles.mapTitle}>Everyday</div>
                    <div>10:00 - 21:00</div>
                </div>
            </div>
        </div >

    );
}

export default Map;